import { useMoodContext } from "@/contexts/MoodContext";
import { moodButtonClicked } from "@/gtmEvents/moodTool";
import { widont } from "@/lib/helpers";
import pushToDataLayer from "@/lib/pushToDataLayer";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

const MoodButton = ({ mood }) => {
  const router = useRouter();

  const { selectedMood } = useMoodContext();

  const isActive = selectedMood?.uid === mood.uid;

  // We need to use next dynamic to import the correct icon. The component will be {option.iconName}Icon.tsx
  const Icon = dynamic(
    () => import(`@/components/Icons/${mood.iconName}Icon`),
    {
      ssr: false,
    }
  );

  const handleClick = () => {
    // Send moodClicked event to GTM
    pushToDataLayer(moodButtonClicked, { mood: mood.title });

    router.push(`/mood/${mood.slug}`, undefined, { shallow: true });
  };

  return (
    <button
      key={mood.uid}
      className={clsx(
        "flex w-full flex-col space-y-2 rounded-xl px-4 py-4 transition ease-out lg:p-4 2xl:p-6",
        {
          "bg-zinc-800 hover:bg-zinc-700": !isActive,
          "bg-zinc-700": isActive,
        }
      )}
      onClick={handleClick}
      type="button"
    >
      <div className={clsx("flex items-center space-x-2")}>
        <div className={clsx("shrink-0 rounded-full text-teal-500")}>
          <Icon className={clsx("h-6 w-6")} />
        </div>
        <div className={clsx("font-spatial text-lg")}>{mood.title}</div>
      </div>
      <div className={clsx("body-text-sm text-left")}>
        {widont(mood.description)}
      </div>
    </button>
  );
};

export default MoodButton;
