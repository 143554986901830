import EntryCarousel from "@/components/EntryCarouselNew";
import clsx from "clsx";

const CollectionCarousel = ({
  heading = "",
  subHeading = "",
  collections = [],
  theme = "dark",
}) => {
  // if there are no entries, create 12 dummy entries
  if (collections.length === 0) {
    for (let i = 0; i < 12; i++) {
      collections.push({
        id: i,
        title: "",
        summary: "",
        sectionHandle: "",
      });
    }
  }

  return (
    <div className={clsx("CollectionCarousel", "space-y-8")}>
      {!!heading && (
        <div>
          <div className={clsx("space-y-1 px-6 lg:px-12")}>
            <h2 className={clsx("heading-sm--spatial")}>{heading}</h2>
            {!!subHeading && (
              <div className={clsx("body-text-sm")}>{subHeading}</div>
            )}
          </div>
        </div>
      )}
      <div>
        <EntryCarousel entries={collections} theme={theme} scrollbar={false} />
      </div>
    </div>
  );
};

export default CollectionCarousel;
