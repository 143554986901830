import { useMoodContext } from "@/contexts/MoodContext";
import {
  backButtonClickedOnMoodSelectionView,
  backButtonClickedOnRecipesView,
} from "@/gtmEvents/moodTool";
import pushToDataLayer from "@/lib/pushToDataLayer";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";

const BackButton = () => {
  const { selectedMood, setSelectedMood, setIsOpen } = useMoodContext();

  const router = useRouter();

  const handleClick = () => {
    if (selectedMood === null) {
      router.push("/", undefined, { shallow: true });
    }
    if (selectedMood !== null) {
      router.push(`/mood`, undefined, {
        shallow: true,
      });
    }
    setSelectedMood(null);

    // Send the appropriate event to Google Tag Manager
    const gtmEvent = !!selectedMood
      ? backButtonClickedOnRecipesView
      : backButtonClickedOnMoodSelectionView;

    pushToDataLayer(gtmEvent);
  };

  return (
    <button
      className={clsx(
        "MoodDialog__backButton",
        "button button-light pl-2 pr-4 active:-translate-x-0.5"
      )}
      type="button"
      onClick={handleClick}
    >
      <div>
        <ChevronLeftIcon className={clsx("h-4 w-4")} />
      </div>
      <div>Back</div>
    </button>
  );
};

export default BackButton;
