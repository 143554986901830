import { useAdContext } from "@/contexts/AdContext";
import FreestarAdSlot from "@freestar/pubfig-adslot-react-component";

const Ad = ({ placementName, className = "", targeting = {} }) => {
  const ads = useAdContext();

  if (ads.showAds) {
    return (
      <FreestarAdSlot
        publisher="mob-co-uk"
        placementName={placementName}
        targeting={targeting}
        classList={[
          "Ad",
          "flex",
          "items-center",
          "justify-center",
          "[&>div:not(:empty)]:my-8",
          "print:!hidden",
          ...className.split(" "),
        ]}
      />
    );
  } else {
    return null;
  }
};

export default Ad;
