import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import Balancer from "react-wrap-balancer";
import Target from "./Target";

const FeatureModule = ({
  eyebrow,
  heading,
  summary,
  image,
  href = undefined,
  target = undefined,
}) => {
  const _Wrapper = ({ children, className }) => {
    if (!!href) {
      return (
        <Link href={href} prefetch={false}>
          <a className={className}>{children}</a>
        </Link>
      );
    }

    if (!!target) {
      return (
        <Target target={target} className={className}>
          {children}
        </Target>
      );
    }

    return <div className={className}>{children}</div>;
  };

  // The image might be an array with a single element, or just an object
  const targetImage =
    Array.isArray(image) && image.length > 0 ? image[0] : image;

  return (
    <div className={clsx("FeatureModule", "@container 2xl:container")}>
      <div
        className={clsx(
          "isolate flex flex-col overflow-hidden rounded-2xl bg-white @xl:flex-row "
        )}
      >
        <div className={clsx("@xl:w-1/2")}>
          <_Wrapper
            className={clsx(
              "relative block aspect-square overflow-hidden @2xl:aspect-4/3 @3xl:aspect-16/11"
            )}
          >
            {!!targetImage && (
              <Image
                src={targetImage.url}
                layout="fill"
                objectFit="cover"
                alt={targetImage.title}
                className="transition-transform ease-out hover:scale-[1.0125]"
                loader={cloudflareLoader}
              />
            )}
          </_Wrapper>
        </div>
        <div className={clsx("@xl:w-1/2")}>
          <div className={clsx("space-y-4 p-6 lg:p-12")}>
            {!!eyebrow && <div className={clsx("text-sm")}>{eyebrow}</div>}
            <Balancer className={clsx("heading-lg--spatial")}>
              <_Wrapper
                className={clsx("transition ease-out hover:opacity-75")}
              >
                {heading}
              </_Wrapper>
            </Balancer>
            <div
              className={clsx("body-text-sm line-clamp-4 lg:max-w-[66.666%]")}
              dangerouslySetInnerHTML={{ __html: summary }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureModule;
