import AlgoliaDataWrapper from "@/components/AlgoliaDataWrapper";
import EntryCarousel from "@/components/EntryCarouselNew";
import Rail from "@/components/Rail";
import RailCarousel from "@/components/RailCarousel";
import SegmentSelector from "@/components/SegmentSelector";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserCollections from "@/hooks/useCurrentUserCollections";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import useLists from "@/lists/hooks/useLists";
import useUserElements from "@/lists/hooks/useUserElements";
import clsx from "clsx";
import { observer } from "mobx-react";
import Link from "next/link";
import useSWR from "swr";
import AlgoliaTrendingDataWrapper from "./AlgoliaTrendingDataWrapper";

const HomeSegmentSelector = ({ segmentsConfiguration = [] }) => {
  // Get the current day, expressed as a number
  const currentDay = new Date().getDay();

  // Fetch the current user
  const { currentUser, isLoggedIn } = useCurrentUser();

  // Make sure we have the current user's dietary requirements
  const { dietaryRequirementTitles, dietaryRequirementfacetFilters } =
    useCurrentUserDietaryRequirements();

  // For trending, we just need the first dietaryRequirementTitle
  const trendingDietaryRequirementTitle = dietaryRequirementTitles?.[0];

  let facetName, facetValue;

  if (isLoggedIn) {
    facetName = !!trendingDietaryRequirementTitle
      ? "dietaryRequirements.title"
      : undefined;
    facetValue = !!trendingDietaryRequirementTitle
      ? trendingDietaryRequirementTitle
      : undefined;
  } else {
    facetName = "section.handle";
    facetValue = "recipes";
  }

  const preparedSegments = [];

  segmentsConfiguration.map((segmentConfig) => {
    const loggedIn =
      segmentConfig.visibility === "always" ||
      segmentConfig.visibility === "loggedIn";
    const loggedOut =
      segmentConfig.visibility === "always" ||
      segmentConfig.visibility === "loggedOut";

    // If the segmentConfig.typeHandle is "newRecipes" then we want to add a new segment to the preparedSegments array
    if (segmentConfig.typeHandle === "newRecipes") {
      preparedSegments.push({
        key: "newRecipes",
        loggedIn,
        loggedOut,
        label: segmentConfig.heading,
        avatar: null,
        children: (
          <AlgoliaDataWrapper
            indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_latest_desc_absolute`}
            userToken={currentUser?.uid}
            facetFilters={[
              dietaryRequirementfacetFilters,
              "section.handle:recipes",
              "types.title:-Drinks",
              "types.title:-Seasoning",
              "types.title:-Sauces",
            ]}
            renderLoading={() => {
              return (
                <_DummyEntryCarousel showChef={false} showSection={false} />
              );
            }}
            render={(data) => (
              <EntryCarousel
                entries={data.hits}
                theme="light"
                showSection={false}
                showChef={false}
                mobileSlideSize="large"
                carouselName="Top Tabs - New"
              />
            )}
          />
        ),
      });
    }

    if (segmentConfig.typeHandle === "trendingRecipes") {
      preparedSegments.push({
        key: "trending",
        loggedIn,
        loggedOut,
        label: isLoggedIn ? "Trending" : "Trending Recipes",
        avatar: null,
        children: (
          <AlgoliaTrendingDataWrapper
            indexName={`${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}`}
            facetName={facetName}
            facetValue={facetValue}
            renderLoading={() => {
              return (
                <_DummyEntryCarousel showChef={false} showSection={false} />
              );
            }}
            render={(data) => {
              return (
                <EntryCarousel
                  entries={data.hits}
                  theme="light"
                  showSection={false}
                  showChef={false}
                  mobileSlideSize="large"
                  carouselName="Top Tabs - Trending"
                />
              );
            }}
          />
        ),
      });
    }

    if (segmentConfig.typeHandle === "savedRecipes") {
      preparedSegments.push({
        key: "savedRecipes",
        loggedIn,
        loggedOut,
        label: segmentConfig.heading,
        avatar: null,
        children: <_AlgoliaDataWrapperWithSavedRecipes />,
      });
    }

    if (segmentConfig.typeHandle === "plannerRecipes") {
      preparedSegments.push({
        key: "onYourPlanner",
        loggedIn,
        loggedOut,
        label: segmentConfig.heading,
        avatar: null,
        children: <_AlgoliaDataWrapperWithPlannerRecipes />,
      });
    }

    if (segmentConfig.typeHandle === "rail") {
      const rail = segmentConfig.rail?.[0];

      preparedSegments.push({
        key: segmentConfig.id,
        loggedIn,
        loggedOut,
        label: segmentConfig.heading,
        avatar: null,
        children:
          rail.typeHandle === "editorialRail" ? (
            <RailCarousel
              entries={rail.recipes}
              key={rail.id}
              theme="light"
              showHeading={false}
              showChef={false}
              showSection={false}
              className=""
            />
          ) : (
            <Rail
              rail={rail}
              shuffleSeed={currentDay}
              theme="light"
              showHeading={false}
              showChef={false}
              showSection={false}
              className=""
              carouselClassName=""
            />
          ),
      });
    }
  });

  const getOrderedSegments = () => {
    // Put new first for everybody
    return preparedSegments.sort((a, b) => {
      if (a.key === "newRecipes") return -1;
      if (b.key === "newRecipes") return 1;
      return 0;
    });
  };

  return (
    <div
      className={clsx(
        "bg-zinc-900 pb-6 pt-4.5 lg:pb-8 lg:pt-5 2xl:pb-10 2xl:pt-6"
      )}
    >
      <SegmentSelector segments={getOrderedSegments()}></SegmentSelector>
    </div>
  );
};

const _AlgoliaDataWrapperWithSavedRecipes = observer(() => {
  // Fetch the current user
  const { currentUser, isFetching } = useCurrentUser();

  // Fetch this user's collections
  const { savedRecipeIds, isLoading, error } = useCurrentUserCollections();

  // Fetch user elements for new lists system
  const { elementIds, isLoadingUserElements } = useUserElements({
    shouldFetch: currentUser?.listsMigrated,
  });

  // Handle loading and error states
  if (isLoading || isFetching || isLoadingUserElements)
    return (
      <div className={clsx("flex w-full items-center justify-center p-32")}>
        Loading...
      </div>
    ); // Show a loading state
  if (error)
    return (
      <div className={clsx("flex w-full items-center justify-center p-32")}>
        Error loading recipes
      </div>
    ); // Show error state

  if (currentUser.listsMigrated) {
    // New lists system
    if (!elementIds || elementIds.length === 0)
      return (
        <_EmptyState
          message={"You haven't saved any recipes yet."}
          label={"Find Recipes"}
          href={"/search"}
        />
      );

    const limitedUserElements = elementIds.slice(0, 24);
    const userElementIdsFilter = limitedUserElements
      .map((elementId) => `objectID:${elementId}`)
      .join(" OR ");

    return (
      <AlgoliaDataWrapper
        shouldFetch={!isLoadingUserElements}
        userToken={currentUser?.uid}
        filters={userElementIdsFilter}
        renderLoading={() => {
          return <_DummyEntryCarousel showSection={false} showChef={false} />;
        }}
        render={(data) => (
          <EntryCarousel
            entries={data.hits}
            theme="light"
            showSection={false}
            showChef={false}
            carouselName="Top Tabs - Saved Recipes"
            mobileSlideSize="large"
          />
        )}
      />
    );
  } else {
    // Old collections system
    if (!savedRecipeIds || savedRecipeIds.length === 0)
      return (
        <_EmptyState
          message={"You haven't saved any recipes yet."}
          label={"Find Recipes"}
          href={"/search"}
        />
      );

    const limitedSavedRecipeIds = savedRecipeIds.slice(0, 24);
    const savedRecipeIdsFilter = limitedSavedRecipeIds
      .map((id) => `objectID:${id}`)
      .join(" OR ");

    return (
      <AlgoliaDataWrapper
        shouldFetch={!isFetching && !isLoading}
        userToken={currentUser?.uid}
        filters={savedRecipeIdsFilter}
        renderLoading={() => {
          return <_DummyEntryCarousel showSection={false} showChef={false} />;
        }}
        render={(data) => (
          <EntryCarousel
            entries={data.hits}
            theme="light"
            showSection={false}
            showChef={false}
            carouselName="Top Tabs - Saved Recipes"
            mobileSlideSize="large"
          />
        )}
      />
    );
  }
});

const _DummyEntryCarousel = ({ showSection, showChef }) => {
  const entries = [];
  for (let i = 0; i < 12; i++) {
    entries.push({
      id: i,
      title: "",
      summary: "",
      sectionHandle: "-",
    });
  }
  return (
    <EntryCarousel
      entries={entries}
      theme="light"
      showSection={showSection}
      showChef={showChef}
      mobileSlideSize="large"
    />
  );
};

const _AlgoliaDataWrapperWithPlannerRecipes = observer(() => {
  // Fetch the current user
  const { currentUser, isFetching } = useCurrentUser();

  // Use swr to fetch the planner recipes. Avoid fetching if currentUser.id is undefined.
  const { data, error, isLoading } = useSWR(
    currentUser?.id ? `/api/planner/recipes/get?userId=${currentUser.id}` : null
  );

  // Handle loading and error states
  if (isLoading || isFetching)
    return <_DummyEntryCarousel showSection={false} showChef={false} />; // Show a loading state
  if (error) return <div>Error loading recipes</div>; // Show error state

  const recipeIds = data?.recipeIds || [];

  // If there are no recipeIds, return empty state
  if (recipeIds.length === 0)
    return (
      <_EmptyState
        message={"You don't have any recipes on your planner yet."}
        label={"Get Started"}
        href={"/planner"}
      />
    );

  // Limit recipeIds to 24
  const limitedRecipeIds = recipeIds.slice(0, 24);

  // Now turn this into an Algolia filter for objectID
  const savedRecipeIdsFilter = limitedRecipeIds
    .map((id) => `objectID:${id}`)
    .join(" OR ");

  return (
    <AlgoliaDataWrapper
      shouldFetch={!isFetching && !isLoading}
      userToken={currentUser?.uid}
      filters={savedRecipeIdsFilter}
      renderLoading={() => {
        return <_DummyEntryCarousel showSection={false} showChef={false} />;
      }}
      render={(data) => {
        return (
          <EntryCarousel
            entries={data.hits}
            theme="light"
            carouselName="Top Tabs - Planner"
            showSection={false}
            showChef={false}
            mobileSlideSize="large"
          />
        );
      }}
    />
  );
});

const _EmptyState = ({ message, label, href }) => {
  return (
    <div className={clsx("relative")}>
      <div>
        <_DummyEntryCarousel showSection={false} showChef={false} />
      </div>
      <div
        className={clsx(
          "absolute inset-0 flex flex-col items-center justify-center"
        )}
      >
        <div
          className={clsx(
            "flex -translate-y-6 flex-col justify-center space-y-4 px-16"
          )}
        >
          <div className={clsx("text-center text-base font-normal")}>
            {message}
          </div>

          <Link href={href}>
            <a
              className={clsx(
                "mx-auto  grow-0 rounded-full bg-zinc-50 px-8 py-3 text-zinc-950 transition ease-out hover:bg-zinc-200"
              )}
            >
              <div className={clsx("text-sm")}>{label}</div>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default observer(HomeSegmentSelector);
