import GTMEvent from "@/types/GTMEvent";

/* Mood Tool events */
const category = "moodTool";

export const activationButttonClicked = {
  category,
  name: "activationButtton",
  action: "clicked",
  label: "User clicked mood tool activation button",
} as GTMEvent;

export const moodButtonClicked = {
  category,
  name: "moodButton",
  action: "clicked",
  label: "User selected a mood",
};

export const moodTabClicked = {
  category,
  name: "moodTab",
  action: "clicked",
  label: "User clicked on a mood tab",
};

export const recipeClicked = {
  category,
  name: "recipe",
  action: "clicked",
  label: "User clicked on a recipe",
};

export const moreRecipesButtonClicked = {
  category,
  name: "moreRecipesButton",
  action: "clicked",
  label: "User clicked the More Recipes button",
};

export const backButtonClickedOnMoodSelectionView = {
  category,
  name: "moodSelectionViewBackButton",
  action: "clicked",
  label: "User clicked back button before selecting a moon",
};

export const backButtonClickedOnRecipesView = {
  category,
  name: "recipesViewBackButton",
  action: "clicked",
  label: "User clicked back button on recipe view",
};

const moodTool = {
  activationButttonClicked,
  moodButtonClicked,
  moodTabClicked,
  recipeClicked,
  moreRecipesButtonClicked,
  backButtonClickedOnMoodSelectionView,
  backButtonClickedOnRecipesView,
};

export default moodTool;
