import DeliverooLogo from "@/components/MoodDialog/DeliverooLogo";
import { useMoodContext } from "@/contexts/MoodContext";
import { activationButttonClicked } from "@/gtmEvents/moodTool";
import cloudflareLoader from "@/lib/cloudflare-images";
import { widont } from "@/lib/helpers";
import { pushToDataLayer } from "@/lib/pushToDataLayer";
import clsx from "clsx";
import { motion } from "framer-motion";
import Image from "next/image";
import { useRouter } from "next/router";
import { useInView } from "react-hook-inview";

const defaultImages = [
  {
    id: 1,
    url: "https://files.mob-cdn.co.uk/recipes/Chicken-Kale-and-Potato-Soup.jpg",
    title: "Chicken Kale and Potato Soup",
  },
  {
    id: 2,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Veggie-Meatball-Kebabs-With-Fennel-Pumpkin-Seed-Slaw.jpg",
    title: "Veggie Meatball Kebabs With Fennel Pumpkin Seed Slaw",
  },
  {
    id: 3,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Grilled-Halloumi-Spring-Salad.jpg",
    title: "Grilled Halloumi Spring Salad",
  },
  {
    id: 4,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Creamy-Kale-Conchiglie-With-Charred-Tenderstem.jpg",
    title: "Creamy Kale Conchiglie With Charred Tenderstem",
  },
  {
    id: 5,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Four-Cheese-Lasagne.jpg",
    title: "Four Cheese Lasagne",
  },
  {
    id: 6,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Sesame-Miso-Chicken-Caesar-Salad.jpg",
    title: "Sesame Miso Chicken Caesar Salad",
  },
  {
    id: 7,
    url: "https://files.mob-cdn.co.uk/recipes/2023/02/Turkish-Eggs-With-Spiced-Butter-Herby-Yoghurt.jpg",
    title: "Turkish Eggs With Spiced Butter Herby Yoghurt",
  },
  {
    id: 8,
    url: "https://files.mob-cdn.co.uk/recipes/Chicken-Kale-and-Potato-Soup.jpg",
    title: "Chicken Kale and Potato Soup",
  },
  {
    id: 9,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Veggie-Meatball-Kebabs-With-Fennel-Pumpkin-Seed-Slaw.jpg",
    title: "Veggie Meatball Kebabs With Fennel Pumpkin Seed Slaw",
  },
  {
    id: 10,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Four-Cheese-Lasagne.jpg",
    title: "Four Cheese Lasagne",
  },
  {
    id: 11,
    url: "https://files.mob-cdn.co.uk/recipes/2023/03/Sesame-Miso-Chicken-Caesar-Salad.jpg",
    title: "Sesame Miso Chicken Caesar Salad",
  },
  {
    id: 12,
    url: "https://files.mob-cdn.co.uk/recipes/2023/02/Turkish-Eggs-With-Spiced-Butter-Herby-Yoghurt.jpg",
    title: "Turkish Eggs With Spiced Butter Herby Yoghurt",
  },
];

const MoodDialogCTA = ({
  heading = `Can’t decide what to eat?`,
  introduction = `Discover a dish, based on your mood.`,
  images = defaultImages,
  buttonLabel = `Get Started`,
}) => {
  return (
    <div className={clsx("")}>
      <div
        className={clsx(
          "relative flex items-center overflow-hidden rounded-2xl bg-white px-6 py-6 lg:h-80 lg:rounded-none lg:bg-zinc-100 lg:px-12 lg:py-12 xl:h-96"
        )}
      >
        <div className={clsx("flex flex-col")}>
          <div
            className={clsx(
              "w-3/5 space-y-4 xs:w-2/3 sm:w-1/2 md:w-2/3 lg:w-full lg:space-y-4"
            )}
          >
            <div className={clsx("space-y-1 2xl:space-y-2")}>
              <h2
                className={clsx(
                  "font-spatial text-2xl leading-6 lg:text-3xl xl:text-4xl 2xl:text-4xl"
                )}
              >
                {heading}
              </h2>
              <div
                className={clsx("body-text-sm lg:body-text 2xl:body-text-lg")}
              >
                {widont(introduction)}
              </div>
            </div>
            <div>
              <_Button label={buttonLabel} />
            </div>
          </div>

          <div className={clsx("pt-4")}>
            <_DeliverooPromotion />
          </div>
        </div>

        <div
          className={clsx(
            "absolute right-0 transition ease-out",
            "w-72 -translate-y-[20%] translate-x-[60%]",
            "xs:w-80 xs:translate-x-[55%]",
            "sm:w-96 sm:translate-x-[33.333%]",
            "md:w-112 md:translate-x-[10%]",
            "lg:w-[47.5vw] lg:translate-x-[10%]",
            "xl:w-[55vw] xl:translate-x-[20%]",
            "2xl:w-[60vw] 2xl:translate-x-[15%]",
            "3xl:w-[60vw] 3xl:translate-x-0"
          )}
        >
          <div className={clsx("-rotate-45")}>
            <_ImageGrid images={images} />
          </div>
        </div>
      </div>
    </div>
  );
};

const _DeliverooPromotion = () => {
  return (
    <div className={clsx("flex items-center space-x-1")}>
      <div>
        <Image
          src={`/images/mood-tool/deliveroo-bag.png`}
          alt="Deliveroo illustration"
          width={65}
          height={80}
          sizes="65px"
          layout="fixed"
          quality={100}
        />
      </div>
      <div
        className={clsx(
          "relative -top-[4px] max-w-72 text-balance font-spatial text-[15px] font-light leading-[1]"
        )}
      >
        {`We're making ours with groceries from`}{" "}
        <span className={clsx("relative top-[3px] inline-block w-[58px]")}>
          <DeliverooLogo />
        </span>
      </div>
    </div>
  );
};

const _Button = ({ label = "" }) => {
  const router = useRouter();

  const { setIsOpen } = useMoodContext();

  const handleClick = (ev) => {
    router.push(`/mood`, undefined, { shallow: true });

    // Push an event to the data layer
    pushToDataLayer(activationButttonClicked);
  };

  return (
    <button className={clsx("button button-dark")} onClick={handleClick}>
      <div className={clsx("whitespace-nowrap text-sm")}>{label}</div>
    </button>
  );
};

const _ImageGrid = ({ images }) => {
  const [ref, isVisible] = useInView({
    unobserveOnEnter: true,
  });

  // Defining variants for animations
  const variants = {
    hidden: { opacity: 0, translateY: 50 },
    visible: { opacity: 1, translateY: 0 },
  };

  return (
    <motion.div
      className="direction-rtl grid grid-cols-4 grid-rows-3 gap-2 lg:gap-4"
      ref={ref}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      variants={variants}
    >
      {images.slice(0, 11).map((image, index) => {
        return (
          <motion.div
            key={image.id}
            className={clsx(
              "relative col-span-1 aspect-3/4 overflow-hidden rounded-md lg:rounded-2xl",
              {
                invisible: index === 3,
              }
            )}
            variants={variants}
            transition={{
              duration: 0.75,
              delay: index * 0.15,
              ease: "easeOut",
            }}
          >
            <Image
              src={image.url}
              layout="fill"
              objectFit="cover"
              alt={image.title}
              priority
              loader={cloudflareLoader}
              sizes="(max-width: 768px) 240px, (max-width: 1540px) 240px, 390px"
            />
            {index}
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default MoodDialogCTA;
