import HomeBuilder from "@/components/HomeBuilder";
import HomeSegmentSelector from "@/components/HomeSegmentSelector";
import { useAdContext } from "@/contexts/AdContext";
import useFaqs from "@/hooks/useFaqs";
import craft from "@/lib/craft";
import getCommonStaticProps from "@/lib/getCommonStaticProps";
import QueryArticles from "@/queries/QueryArticles.graphql";
import QueryHome from "@/queries/QueryHome.graphql";
import FaqsSection from "@components/FaqsSection";
import { observer } from "mobx-react";
import Head from "next/head";
import { useEffect } from "react";
import { Seomatic } from "react-seomatic";

export async function getStaticProps({ params, previewData, preview }) {
  // Fetch common static properties
  const commonStaticProps = await getCommonStaticProps();

  const queries = [
    {
      document: QueryHome,
    },
    {
      document: QueryArticles,
      variables: {
        limit: 12,
      },
    },
  ];

  const response = await craft(queries, preview ? previewData.token : null);

  const { pageEntry } = response[0];
  const { articles } = response[1];

  return {
    props: {
      ...commonStaticProps,
      pageEntry,
      articles,
      seomatic: pageEntry.seomatic,
    },
    revalidate: process.env.VERCEL_ENV !== "production" ? 1 : 3600,
  };
}

const Home = ({
  pageEntry,
  globalFaqs,
  articles = [],
  premiumPromotion,
  testimonials = [],
}) => {
  // Set showAds to true for this page
  const { setShowAds } = useAdContext();
  useEffect(() => setShowAds(true));

  const faqsToDisplay = useFaqs(
    globalFaqs.items,
    pageEntry.homeFaqs,
    "homepage"
  );

  const testimonialsToDisplay = testimonials?.filter((testimonial) =>
    testimonial.showTestimonialOnPages.includes("homepage")
  );

  return (
    <>
      <Seomatic Head={Head} {...pageEntry.seomatic} />

      <HomeSegmentSelector segmentsConfiguration={pageEntry.segments} />

      <HomeBuilder
        blocks={pageEntry.homeBuilder}
        articles={articles}
        testimonialsToDisplay={testimonialsToDisplay}
        premiumPromotion={premiumPromotion}
      />

      <FaqsSection
        {...globalFaqs.intro}
        faqs={faqsToDisplay}
        generateSchema
        divider={false}
      />
    </>
  );
};

export default observer(Home);
