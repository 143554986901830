import MoodButton from "@/components/MoodDialog/MoodButton";
import clsx from "clsx";
import { useEffect } from "react";
import useSWR from "swr";

const MoodButtons = () => {
  const { data: moods, error, isLoading } = useSWR("/api/moods");

  return (
    <div className={clsx("relative flex-1")}>
      <div className={clsx("space-y-4 px-6 py-6")}>
        {moods.map((mood) => {
          return <MoodButton key={mood.uid} mood={mood} />;
        })}
      </div>
    </div>
  );
};

export default MoodButtons;
