import Rail from "@/components/Rail";
import RailCarousel from "@/components/RailCarousel";
import getScheduleSlot from "@/rails/lib/getScheduleSlot";
import { observer } from "mobx-react";

const RailGroup = observer(({ railGroup }) => {
  const scheduleSlot = getScheduleSlot();

  const rail = railGroup?.[0]?.[scheduleSlot]?.[0];

  if (!rail) return null;

  if (rail.typeHandle === "editorialRail") {
    return (
      <RailCarousel
        entries={rail.recipes}
        key={rail.id}
        summary={rail.summary}
        heading={rail.frontendTitle ?? rail.title}
        className="space-y-4 py-6 lg:space-y-6 lg:py-8 xl:py-12 2xl:space-y-8"
      />
    );
  }

  // Get the current day, expressed as a number
  const currentDay = new Date().getDay();

  return <Rail rail={rail} shuffleSeed={currentDay} />;
});

export default RailGroup;
