import EntryGrid from "@/components/EntryGrid";
import Spinner from "@/components/Spinner";
import { useMoodContext } from "@/contexts/MoodContext";
import { recipeClicked } from "@/gtmEvents/moodTool";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import pushToDataLayer from "@/lib/pushToDataLayer";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import useSWR from "swr";
import EntryThumbnail from "../EntryThumbnail";

const MoodRecipes = () => {
  const { data: moods, isLoading: isMoodsLoading } = useSWR("/api/moods");

  const { selectedMood } = useMoodContext();

  // Fetch the recipes for this mood
  const {
    data: currentMood,
    error,
    isLoading: isMoodLoading,
  } = useSWR(!!selectedMood ? `/api/moods/${selectedMood.uid}` : null);

  // Fetch the current user
  // const { isLoggedIn } = useCurrentUser(); // TODO: remove tenporarily until there are more recipes

  // const { dietaryRequirementIds } = useCurrentUserDietaryRequirements(); // TODO: remove tenporarily until there are more recipes

  const recipes = currentMood?.recipes || [];

  // Filter out recipes that don't match the user's dietary requirements
  const filteredRecipes = recipes;

  /*
  if (isLoggedIn && dietaryRequirementIds.length > 0) {
    filteredRecipes = recipes.filter((recipe) => {
      return recipe.dietaryRequirements.some((dietaryRequirement) =>
        dietaryRequirementIds.includes(parseInt(dietaryRequirement.id))
      );
    });
  }
	// TODO: remove tenporarily until there are more recipes
	*/

  if (isMoodsLoading) {
    return (
      <div className={clsx("flex items-center justify-center p-12")}>
        <Spinner />
      </div>
    );
  }

  // Create an array of dummy items
  const dummyHits = [];
  for (let i = 0; i < 4; i++) {
    dummyHits.push({
      id: i,
      title: "",
      summary: "",
      sectionHandle: "-",
    });
  }

  return (
    <div className={clsx("MoodRecipes", "px-6 pb-6 md:px-12 md:pb-12")}>
      {moods.map((mood) => {
        return (
          <Tabs.Content key={mood.uid} value={mood.uid} className={clsx("")}>
            <div className={clsx("MoodRecipes__container", "@container")}>
              <ul
                className={clsx(
                  "MoodRecipes__grid",
                  "grid grid-cols-12 gap-x-4 gap-y-4 xs:gap-x-6 xs:gap-y-6"
                )}
              >
                {filteredRecipes.length > 0 &&
                  filteredRecipes.map((recipe, index) => (
                    <li
                      key={recipe.id}
                      className={clsx(
                        "MoodRecipes__item",
                        "col-span-12 @xs:col-span-6 @md:col-span-6 @lg:col-span-4 @3xl:col-span-3 @7xl:col-span-2"
                      )}
                    >
                      <EntryThumbnail
                        entry={recipe}
                        showSummary={false}
                        showSection={false}
                        showChef={false}
                        priority={index === 0}
                        theme="light"
                        onClick={() => {
                          pushToDataLayer(recipeClicked, {
                            recipeId: recipe.id,
                            recipeTitle: recipe.title,
                            recipeSlug: recipe.slug,
                          });
                        }}
                      />
                    </li>
                  ))}

                {filteredRecipes.length === 0 &&
                  dummyHits.map((dummyHit) => (
                    <li
                      key={dummyHit.id}
                      className={clsx(
                        "MoodRecipes__dummyItem",
                        "col-span-12 @xs:col-span-6 @md:col-span-6 @lg:col-span-4 @3xl:col-span-3 @7xl:col-span-2"
                      )}
                    >
                      <EntryThumbnail
                        entry={dummyHit}
                        showSummary={false}
                        showSection={false}
                        showChef={false}
                        theme="light"
                      />
                    </li>
                  ))}
              </ul>
            </div>
          </Tabs.Content>
        );
      })}
    </div>
  );
};

export default MoodRecipes;
