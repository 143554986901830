import PoweredByDeliveroo from "@/components/MoodDialog/PoweredByDeliveroo";
import clsx from "clsx";
import Image from "next/image";

const Promotion = ({ direction = "ltr", straplinePosition = "top" }) => {
  return (
    <div
      className={clsx("flex items-center gap-x-2", {
        "flex-row-reverse": direction === "rtl",
        "flex-row": direction === "ltr",
      })}
    >
      <div className="flex h-14 w-14 items-center justify-center rounded-full bg-teal-500">
        <Image
          src={`/images/mood-tool/deliveroo-bag.png`}
          alt="Deliveroo bag illustration"
          width={45}
          height={48}
          sizes="45px"
          quality="100"
          layout="fixed"
        />
      </div>
      <div
        className={clsx("", {
          "pr-1": direction === "rtl",
          "pl-1": direction === "ltr",
        })}
      >
        <PoweredByDeliveroo
          direction={direction}
          straplinePosition={straplinePosition}
        />
      </div>
    </div>
  );
};

export default Promotion;
