import DeliverooLogo from "@/components/MoodDialog/DeliverooLogo";
import clsx from "clsx";

const PoweredByDeliveroo = ({
  showStrapline = true,
  straplinePosition = "top",
  direction = "ltr",
}) => {
  return (
    <div
      className={clsx("flex", {
        "flex-col": straplinePosition === "bottom",
        "flex-col-reverse": straplinePosition === "top",
      })}
    >
      {showStrapline && (
        <div
          className={clsx(
            "text-balance py-1 font-spatial text-base font-light leading-tight"
          )}
        >
          {`Groceries delivered in as little as 20 mins`}
        </div>
      )}
      <div
        className={clsx("flex items-center space-x-2", {
          "justify-end": direction === "rtl",
        })}
      >
        <div
          className={clsx(
            "translate-y-[1px] whitespace-nowrap font-body text-xs"
          )}
        >
          Powered by
        </div>
        <div className={clsx("h-5 w-18")}>
          <DeliverooLogo />
        </div>
      </div>
    </div>
  );
};

export default PoweredByDeliveroo;
