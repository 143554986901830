import DraggableScrollArea from "@/components/DraggableScrollArea";
import { useMoodContext } from "@/contexts/MoodContext";
import { moodTabClicked } from "@/gtmEvents/moodTool";
import pushToDataLayer from "@/lib/pushToDataLayer";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { memo } from "react";
import useSWR from "swr";

const TabsList = () => {
  const router = useRouter();

  const { data: moods } = useSWR("/api/moods");

  const { selectedMood } = useMoodContext();

  return (
    <div className={clsx("relative")}>
      <DraggableScrollArea enableSnap={false}>
        <div
          className={clsx("MoodDialog__tabsListWrapper", "", {
            "px-6": !selectedMood,
            "px-6 md:px-12": !!selectedMood,
          })}
        >
          <Tabs.List className={clsx("MoodDialog__tabsList", "flex space-x-2")}>
            {moods.map((mood) => {
              // We need to use next dynamic to import the correct icon. The component will be {option.iconName}Icon.tsx
              const Icon = dynamic(
                () => import(`@/components/Icons/${mood.iconName}Icon`),
                {
                  ssr: false,
                }
              );

              const isActive = selectedMood?.uid === mood.uid;

              return (
                <Tabs.Trigger
                  key={mood.uid}
                  value={mood.uid}
                  className={clsx(
                    "h-component flex items-center space-x-2 rounded-full border pl-3 pr-5 font-body text-sm transition ease-out ",
                    {
                      "border-deliveroo bg-deliveroo text-zinc-950": isActive,
                      "border-zinc-700 bg-zinc-950 text-zinc-50 hover:bg-zinc-900":
                        !isActive,
                    }
                  )}
                  onClick={() => {
                    router.push(`/mood/${mood.slug}`, undefined, {
                      shallow: true,
                    });

                    pushToDataLayer(moodTabClicked, { mood: mood.title });
                  }}
                >
                  <div className={clsx("h-5 w-5")}>
                    <Icon
                      className={clsx("h-full w-full transition ease-out")}
                    />
                  </div>
                  <div>{mood.title}</div>
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
        </div>
      </DraggableScrollArea>
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 z-10 h-full w-6 bg-gradient-to-r from-transparent to-zinc-900"
        )}
      ></div>
    </div>
  );
};

export default memo(TabsList);
