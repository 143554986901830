import Ad from "@/components/Ad";
import AlgoliaDataWrapper from "@/components/AlgoliaDataWrapper";
import ArticleGrid from "@/components/ArticleGrid";
import ChefCarousel from "@/components/ChefCarousel";
import CollectionCarousel from "@/components/CollectionCarousel";
import ComingSoonCarousel from "@/components/ComingSoonCarousel";
import FeatureModule from "@/components/FeatureModule";
import MobPlusPromotion from "@/components/MobPlusPromotion";
import ModuleHeading from "@/components/ModuleHeading";
import MoodDialog from "@/components/MoodDialog";
import MoodDialogCTA from "@/components/MoodDialog/CTA";
import NewsletterModule from "@/components/NewsletterModule";
import ProductsCarousel from "@/components/ProductsCarousel";
import RailGroup from "@/components/RailGroup";
import useCurrentUser from "@/hooks/useCurrentUser";
import useCurrentUserDietaryRequirements from "@/hooks/useCurrentUserDietaryRequirements";
import getSectionNameFromHandle from "@/lib/getSectionNameFromHandle";
import clsx from "clsx";
import { observer } from "mobx-react";
import TestimonialsSection from "@/components/TestimonialsSection";
import AboutMobPremiumBlock from "./AboutMobPremiumBlock";

const HomeBuilder = ({
  blocks,
  articles = [],
  premiumPromotion,
  testimonialsToDisplay,
}) => {
  return (
    <>
      {blocks.map((block, index) => {
        let selectedBlock;

        switch (block.typeHandle) {
          case "chefCarousel":
            selectedBlock = <_ChefCarousel key={block.id} {...block} />;
            break;
          case "railGroup":
            selectedBlock = (
              <RailGroup key={block.id} {...block} index={index} />
            );
            break;
          case "comingSoonCarousel":
            selectedBlock = <_ComingSoonCarousel key={block.id} {...block} />;
            break;
          case "feature":
            selectedBlock = <_FeatureModule key={block.id} {...block} />;
            break;
          case "customFeature":
            selectedBlock = <_CustomFeatureModule key={block.id} {...block} />;
            break;
          case "mobPlusPromotion":
            selectedBlock = <_MobPlusPromotion key={block.id} {...block} />;
            break;
          case "collectionCarousel":
            selectedBlock = <_CollectionCarousel key={block.id} {...block} />;
            break;
          case "shopModule":
            selectedBlock = <_ShopModule key={block.id} {...block} />;
            break;
          case "newsletterModule":
            selectedBlock = <_NewsletterModule key={block.id} {...block} />;
            break;
          case "articlesModule":
            selectedBlock = (
              <_ArticlesModule key={block.id} {...block} articles={articles} />
            );
            break;
          case "advert":
            selectedBlock = <_Ad key={block.id} {...block} />;
            break;
          case "spacer":
            selectedBlock = <_Spacer key={block.id} {...block} />;
            break;
          case "moodDialog":
            selectedBlock = <_MoodDialog key={block.id} {...block} />;
            break;
          case "testimonials":
            selectedBlock = (
              <TestimonialsSection
                key={block.id}
                testimonials={testimonialsToDisplay}
                {...block}
              />
            );
            break;
          case "premiumPromo":
            selectedBlock = (
              <AboutMobPremiumBlock
                key={block.id}
                blockData={premiumPromotion}
              />
            );
            break;
          default:
            selectedBlock = (
              <div key={block.id} className={clsx("hidden")}>
                {block.typeHandle} in development
              </div>
            );
            break;
        }

        return (
          <div key={block.id} className={clsx("odd:bg-white even:bg-zinc-100")}>
            {selectedBlock}
          </div>
        );
      })}
    </>
  );
};

const _Spacer = observer(({ hiddenForUsers = false }) => {
  // Fetch the current user
  const { isLoggedIn } = useCurrentUser();

  if (isLoggedIn && hiddenForUsers) return null;

  return <div className={clsx("h-6 xl:h-12")} />;
});

const _ChefCarousel = observer(({ chefs, heading, introduction }) => {
  return (
    <div className={clsx("py-6 lg:py-8 xl:py-12")}>
      <div className={clsx("grid w-full grid-cols-12 gap-y-6 sm:gap-x-6")}>
        <div
          className={clsx(
            "col-span-12 shrink-0 space-y-1 px-6 lg:col-span-3 lg:pl-12"
          )}
        >
          <h2 className={clsx("heading-sm--spatial")}>{heading}</h2>
          <div
            className={clsx("body-text-sm text-zinc-800")}
            dangerouslySetInnerHTML={{ __html: introduction }}
          ></div>
        </div>
        <div className={clsx("col-span-12 lg:col-span-9")}>
          <ChefCarousel chefs={chefs} theme="dark" className="px-6 lg:px-12" />
        </div>
      </div>
    </div>
  );
});

const _ComingSoonCarousel = observer(({ heading, introduction }) => {
  return (
    <div className={clsx("bg-zinc-50 py-6 lg:py-8 xl:py-12")}>
      <ComingSoonCarousel heading={heading} introduction={introduction} />
    </div>
  );
});

const _CustomFeatureModule = observer(
  ({ eyebrow, heading, summary, image, target, visibility = "all" }) => {
    const { isLoggedIn } = useCurrentUser();

    // Visibility can be all, loggedIn, loggedOut
    if (visibility === "loggedIn" && !isLoggedIn) return null;
    if (visibility === "loggedOut" && isLoggedIn) return null;

    return (
      <div
        className={clsx(
          "FeatureModuleBlock",
          "bg-zinc-100 px-6 py-6 lg:px-12 xl:py-12"
        )}
      >
        <FeatureModule
          eyebrow={eyebrow}
          heading={heading}
          summary={summary}
          image={image}
          target={target}
        />
      </div>
    );
  }
);

const _FeatureModule = observer(({ entries }) => {
  // Fetch the current user
  const { currentUser, isFetching } = useCurrentUser();

  const { dietaryRequirementfacetFilters } =
    useCurrentUserDietaryRequirements();

  const facetFilters = [
    dietaryRequirementfacetFilters,
    entries.map((entry) => `objectID:${entry.id}`),
  ];

  return (
    <div
      className={clsx(
        "FeatureModuleBlock",
        "bg-zinc-100 px-6 py-6 lg:px-12 xl:py-12"
      )}
    >
      <AlgoliaDataWrapper
        shouldFetch={!isFetching}
        userToken={currentUser?.uid}
        facetFilters={facetFilters}
        hitsPerPage={1}
        render={(data) => {
          const entry = data.hits?.[0];

          if (!entry) return null;

          const image = entry?.image?.[0];

          return (
            <FeatureModule
              eyebrow={getSectionNameFromHandle(entry.sectionHandle)}
              heading={entry.title}
              summary={entry.summary}
              image={image}
              href={`/${entry.uri}`}
            />
          );
        }}
      />
    </div>
  );
});

const _MobPlusPromotion = observer(({ heading, introduction, blocks }) => {
  const { isLoggedOut } = useCurrentUser();

  return (
    <>
      {isLoggedOut && (
        <div>
          <MobPlusPromotion
            heading={heading}
            introduction={introduction}
            blocks={blocks}
          />
        </div>
      )}
    </>
  );
});

const _CollectionCarousel = observer(({ heading, subHeading }) => {
  const { currentUser, isFetching } = useCurrentUser();

  const { dietaryRequirementfacetFilters } =
    useCurrentUserDietaryRequirements();

  return (
    <div className={clsx("bg-zinc-950 py-6 text-zinc-50 lg:py-8 xl:py-12")}>
      <AlgoliaDataWrapper
        shouldFetch={!isFetching}
        userToken={currentUser?.uid}
        facetFilters={[
          dietaryRequirementfacetFilters,
          "section.handle:collections",
        ]}
        renderLoading={() => {
          return (
            <CollectionCarousel
              heading={heading}
              subHeading={subHeading}
              theme="light"
            />
          );
        }}
        render={(data) => {
          return (
            <CollectionCarousel
              heading={heading}
              subHeading={subHeading}
              collections={data?.hits}
              theme="light"
            />
          );
        }}
      />
    </div>
  );
});

const _ShopModule = ({ products, heading, subHeading }) => {
  return (
    <div className={clsx("px-6 py-6 lg:px-12 lg:py-12")}>
      <div
        className={clsx(
          "space-y-6 rounded-2xl bg-zinc-100 py-6 lg:space-y-10 lg:py-10 xl:py-12 2xl:space-y-12"
        )}
      >
        <ModuleHeading heading={heading} subHeading={subHeading} />

        <ProductsCarousel products={products} />
      </div>
    </div>
  );
};

const _NewsletterModule = observer(({ heading, hiddenForUsers = false }) => {
  const { isLoggedIn } = useCurrentUser();

  if (isLoggedIn && hiddenForUsers) return null;

  return (
    <div className={clsx("!bg-white px-6 lg:px-12")}>
      <NewsletterModule heading={heading} />
    </div>
  );
});

const _ArticlesModule = ({ articles, heading, subHeading }) => {
  return (
    <div className={clsx("space-y-12 !bg-white px-6 py-6 lg:px-12 lg:py-12")}>
      <ModuleHeading heading={heading} subHeading={subHeading} />

      <ArticleGrid articles={articles} />
    </div>
  );
};

const _Ad = observer(({ placementName = "mob.co.uk_leaderboard_3" }) => {
  return (
    <Ad
      placementName={placementName}
      className={clsx("px-6 py-6 lg:px-12 lg:py-12")}
    />
  );
});

const _MoodDialog = observer(
  ({ heading, introduction, images, buttonLabel }) => {
    return (
      <div className={clsx("bg-zinc-100 p-6 lg:p-0")}>
        <MoodDialogCTA
          heading={heading}
          introduction={introduction}
          images={images}
          buttonLabel={buttonLabel}
        />
        <MoodDialog />
      </div>
    );
  }
);

export default observer(HomeBuilder);
