import Button from "@/components/Button";
import Promotion from "@/components/MoodDialog//Promotion";
import BackButton from "@/components/MoodDialog/BackButton";
import MoodButtons from "@/components/MoodDialog/MoodButtons";
import MoodRecipes from "@/components/MoodDialog/MoodRecipes";
import TabsList from "@/components/MoodDialog/TabsList";
import Spinner from "@/components/Spinner";
import { useMoodContext } from "@/contexts/MoodContext";
import { moreRecipesButtonClicked } from "@/gtmEvents/moodTool";
import pushToDataLayer from "@/lib/pushToDataLayer";
import * as Dialog from "@radix-ui/react-dialog";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";

const moodsEndpoint = "/api/moods";

const MoodDialog = ({ basePath = "/" }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);

  const { isOpen, setIsOpen, selectedMood, setSelectedMood } = useMoodContext();

  const router = useRouter();

  const {
    data: moods,
    error,
    isLoading,
  } = useSWR(isOpen ? moodsEndpoint : null);

  // If esc is pressed, deselect the mood
  useEffect(() => {
    const handleKeyDown = (event) => {
      event.preventDefault();

      if (event.key === "Escape") {
        if (!!selectedMood) {
          setSelectedMood(null);
        } else {
          setIsOpen(false);
        }
      }
    };

    if (isOpen) document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setSelectedMood, isOpen, setIsOpen, selectedMood]);

  const path = router.asPath;

  const moodSlug = router.query.moodSlug;

  useEffect(() => {
    // On first mount, if the path is and the dialog is not open, open it
    if (path.includes("/mood") && !isOpen) {
      setIsOpen(true);
    }

    const handleRouteChangeComplete = (url) => {
      // If we're on a specific mood view, and the dialog is open, select the mood
      if (!isFirstRender && url.includes("/mood/") && isOpen) {
        // If we're loading the moods, don't do anything yet
        if (!moods) return;

        setSelectedMood(moods.find((mood) => mood.slug === moodSlug));
      }

      // If we're on /mood, and a mood is selected, deselect it
      if (!isFirstRender && url === "/mood" && !!selectedMood) {
        setSelectedMood(null);
      }

      // If we're on the base path, and the dialog is open, close it
      if (!isFirstRender && url === basePath && isOpen) {
        setIsOpen(false);
      }
    };

    // Set isFirstRender to false
    setIsFirstRender(false);

    // Listen for route changes
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    return () => {
      // Unsubscribe from route change events
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [
    basePath,
    isFirstRender,
    isOpen,
    isLoading,
    moods,
    moodSlug,
    path,
    setIsOpen,
    setSelectedMood,
  ]);

  useEffect(() => {
    return () => {};
  }, []);

  const handleOnOpenChange = (isOpen) => {
    setIsOpen(isOpen);

    if (!isOpen) {
      setSelectedMood(null);

      if (router.asPath !== basePath)
        router.push(basePath, undefined, { shallow: true });
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleOnOpenChange}>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount asChild>
              <motion.div
                className={clsx(
                  "MoodDialog__overlay",
                  "fixed inset-0 z-50 bg-black bg-opacity-75"
                )}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              ></motion.div>
            </Dialog.Overlay>

            <Dialog.Content asChild>
              <div
                className={clsx(
                  "MoodDialog__content",
                  "fixed inset-y-0 left-1/2 z-50 flex w-full  -translate-x-1/2 items-center justify-center md:p-6",
                  {
                    "max-w-screen-xs": !selectedMood,
                    "": !!selectedMood,
                  }
                )}
              >
                <motion.div
                  className={clsx(
                    "MoodDialog__mainWrapper",
                    "relative flex h-full max-h-full w-full flex-1 flex-col overflow-auto rounded-none scrollbar-none xs:rounded-2xl",
                    {
                      "bg-zinc-950": !selectedMood,
                      "h-full bg-zinc-900": !!selectedMood,
                    }
                  )}
                  initial={{ opacity: 0, scale: 0.99 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.99 }}
                  transition={{ duration: 0.2 }}
                >
                  {!!selectedMood && (
                    <svg
                      viewBox="0 0 434 230"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={clsx(
                        "MoodDialog__root",
                        "absolute right-0 top-0 z-20 hidden h-full max-h-60 w-1/3 md:block"
                      )}
                    >
                      <path
                        d="M130.006 111.477L596.15 64.8622L596.15 168.848L40.3628 229.806L0.919832 -137.731L108.492 -137.731L130.006 111.477Z"
                        fillOpacity="0.6"
                        className={clsx("fill-deliveroo")}
                      />
                    </svg>
                  )}

                  <div
                    className={clsx(
                      "MoodDialog__backButtonWrapper",
                      "sticky top-0 z-10 flex h-20 items-center py-4 lg:py-6",
                      {
                        "bg-zinc-950 px-6": !selectedMood,
                        "bg-zinc-900 px-6 md:px-12": !!selectedMood,
                      }
                    )}
                  >
                    <BackButton />
                  </div>

                  <div
                    className={clsx(
                      "MoodDialog__main",
                      "relative flex flex-1 flex-col text-zinc-50",
                      {
                        "bg-[url(/images/mood-tool/pattern_01.svg)] bg-fixed bg-[right_top_4rem] bg-no-repeat":
                          !selectedMood,
                      }
                    )}
                  >
                    <Tabs.Root
                      value={selectedMood?.uid}
                      onValueChange={(value) => {
                        setSelectedMood(
                          moods.find((mood) => mood.uid === value)
                        );
                      }}
                      className={clsx("MoodDialog__tabsRoot", "")}
                    >
                      <div
                        className={clsx("MoodDialog__header", "flex flex-col", {
                          "": !selectedMood,
                          "top-20 z-10 bg-zinc-900 lg:sticky": !!selectedMood,
                        })}
                      >
                        <div
                          className={clsx(
                            "MoodDialog__promotion",
                            "block pb-4",
                            {
                              "px-6": !selectedMood,
                              "px-6 md:px-12": !!selectedMood,
                            }
                          )}
                        >
                          <Promotion
                            direction="ltr"
                            straplinePosition="bottom"
                          />
                        </div>

                        <Dialog.Title
                          className={clsx(
                            "MoodDialog__title",
                            "font-spatial text-3xl leading-none 2xl:text-4xl 2xl:leading-none",
                            {
                              "px-6": !selectedMood,
                              "px-6 md:px-12": !!selectedMood,
                            }
                          )}
                        >
                          {`I'm in the mood for something...`}
                        </Dialog.Title>

                        <div className={clsx("flex items-center")}>
                          {!!selectedMood && (
                            <div
                              className={clsx(
                                "MoodDialog__tabsListWrapper",
                                "w-full overflow-hidden py-6"
                              )}
                            >
                              <TabsList />
                            </div>
                          )}
                        </div>
                      </div>

                      {!!selectedMood && <MoodRecipes />}
                    </Tabs.Root>

                    {!!selectedMood && (
                      <div className={clsx("mt-auto px-6 pb-12 lg:px-12")}>
                        <div
                          className={clsx(
                            "flex flex-col space-y-4 rounded-xl bg-white px-6 py-6 lg:flex-row lg:items-center lg:space-y-0 lg:px-9"
                          )}
                        >
                          <div
                            className={clsx(
                              "text-balance text-center font-spatial text-zinc-950"
                            )}
                          >
                            {`Not found what you're looking for?`}
                          </div>
                          <div className={clsx("lg:ml-auto")}>
                            <Button
                              href="/recipes"
                              theme="light"
                              label="More Recipes"
                              onClick={() => {
                                pushToDataLayer(moreRecipesButtonClicked);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {!selectedMood && !isLoading && <MoodButtons />}

                    {!selectedMood && isLoading && (
                      <div
                        className={clsx(
                          "flex items-center justify-center px-6 py-12 md:p-12"
                        )}
                      >
                        <Spinner />
                      </div>
                    )}
                  </div>
                </motion.div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export default MoodDialog;
