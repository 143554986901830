import DietaryRequirementsPopover from "@/components/DietaryRequirementsPopover";
import DraggableScrollArea from "@/components/DraggableScrollArea";
import useCurrentUser from "@/hooks/useCurrentUser";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import usePaywall from "@hooks/usePaywall";
import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

const SegmentSelector = ({ segments }) => {
  const { isLoggedIn } = useCurrentUser();
  const [value, setValue] = useState(segments[0].key);

  useEffect(() => {
    // When the segments change, reset the value to the first segment
    setValue(segments[0].key);
  }, [segments]);

  if (!segments || segments.length === 0) return null;

  // Filter the segments
  // in the unusual circumstance where the segment is !loggedIn and loggedOut and the user is logged in, don't show it
  segments = segments.filter(
    (segment) => !(segment.loggedOut && !segment.loggedIn && isLoggedIn)
  );

  return (
    <Tabs.Root value={value} onValueChange={setValue}>
      <div className={clsx("flex w-full pb-4.5 lg:pb-6")}>
        <div className={clsx("flex-1 overflow-hidden")}>
          <Tabs.List className={clsx("")}>
            <_Tabs segments={segments} />
          </Tabs.List>
        </div>

        <div
          className={clsx(
            "w-18 border-l border-zinc-500 pl-4 pr-4 lg:w-28 lg:pl-6 lg:pr-12"
          )}
        >
          <DietaryRequirementsPopover />
        </div>
      </div>

      {segments.map((segment) => {
        // If the segment is only available to logged in users, and the user is not logged in, we show a padlock
        const isLocked = !segment.loggedOut && !isLoggedIn;

        return (
          <Tabs.Content
            key={segment.key}
            value={segment.key}
            className={clsx("text-zinc-50")}
          >
            {!isLocked && segment.children}
          </Tabs.Content>
        );
      })}
    </Tabs.Root>
  );
};

const _Tabs = ({ segments }) => {
  return (
    <div className={clsx("relative")}>
      <DraggableScrollArea>
        <div className={clsx("flex space-x-3 px-6 lg:px-12")}>
          {segments.map((segment, index) => {
            return (
              <div key={index} className={clsx("w-fit select-none")}>
                <_Tab segment={segment} />
              </div>
            );
          })}
        </div>
      </DraggableScrollArea>
      <div
        className={clsx(
          "pointer-events-none absolute inset-y-0 right-0 w-12 bg-gradient-to-r from-transparent to-zinc-900"
        )}
      ></div>
    </div>
  );
};

const _Tab = ({ segment }) => {
  const { setPaywall } = usePaywall();

  const { isLoggedIn, currentUser } = useCurrentUser();

  let Icon = null;

  // If the segment is only available to logged in users, and the user is not logged in, we show a padlock
  let isLocked = !segment.loggedOut && !isLoggedIn;
  let lockVariant = "personaliseExperience";

  // Handle premium feature segments
  if (segment.key === "onYourPlanner") {
    lockVariant = "createPersonalisedMealPlans";
  } else if (segment.key === "savedRecipes") {
    lockVariant = "saveAndOrganiseRecipes";
  }
  if (
    (segment.key === "savedRecipes" || segment.key === "onYourPlanner") &&
    isLoggedIn &&
    currentUser.plan !== 2
  ) {
    isLocked = true;
  }

  if (isLocked) {
    Icon = LockClosedIcon;
  } else {
    Icon = segment.icon;
  }

  const handleClick = (ev) => {
    if (isLocked) {
      setPaywall({
        isOpen: true,
        isClosable: true,
        variant: lockVariant,
      });
    }
  };

  const _ButtonContent = () => {
    return (
      <>
        {!!Icon && (
          <div className={clsx("")}>
            <Icon
              className={clsx(
                "group-[.active]:stroke-zinc-950 group-[.inactive]:!stroke-zinc-50",
                {
                  "h-3.5 w-3.5": isLocked,
                  "h-4 w-4": !isLocked,
                  [segment.iconClassName]: !!segment.iconClassName && !isLocked,
                }
              )}
            />
          </div>
        )}
        <div
          className={clsx(
            "pointer-events-none select-none whitespace-nowrap font-body text-sm"
          )}
        >
          {segment.label}
        </div>
      </>
    );
  };

  return (
    <div className={clsx("relative")}>
      {!isLocked && (
        <Tabs.Trigger key={segment.key} value={segment.key} asChild>
          <button
            className={clsx(
              "h-component [state='inactive']:inactive [state='active']:active group relative flex items-center space-x-2 rounded-full border font-body text-sm font-medium leading-none transition ease-out",
              "data-[state='inactive']:border-zinc-700 data-[state='inactive']:bg-zinc-950 data-[state='inactive']:text-zinc-50 data-[state='inactive']:hover:bg-zinc-900",
              "data-[state='active']:border-zinc-50 data-[state='active']:bg-zinc-50 data-[state='active']:text-zinc-950",
              "px-3.5 lg:px-5"
            )}
          >
            <_ButtonContent />
          </button>
        </Tabs.Trigger>
      )}
      {isLocked && (
        <button
          className={clsx(
            "h-component group relative flex items-center space-x-2 rounded-full border transition ease-out lg:text-base",
            "border-zinc-700 bg-zinc-950 text-zinc-50 hover:bg-zinc-900 focus:border-zinc-700",
            "px-3.5 leading-none lg:px-5"
          )}
          onClick={handleClick}
        >
          <_ButtonContent />
        </button>
      )}
    </div>
  );
};

const Segment = ({ children }) => {
  return (
    <div className={clsx("")}>
      <div>{children}</div>
    </div>
  );
};

SegmentSelector.Segment = Segment;

export default observer(SegmentSelector);
